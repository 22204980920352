import { atom, action } from "nanostores";
import { getCart, getPrice, removeCoupon, validateCoupon } from "./utils";
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { CartData } from "./cartTypes";

const persistentAtom = (name: string, initial: undefined | string | boolean | null = undefined, opts = {}) => {
  let encode = opts.encode || JSON.stringify
  let decode = opts.decode || JSON.parse

  let store = atom(initial)
  store.isLocalStorage = true;

  let set = store.set
  store.set = newValue => {
    if (typeof newValue === 'undefined') {
      delete localStorage[name]
    } else {
      localStorage[name] = encode(newValue)
    }
    set(newValue)
  }

  store.restore = () => {
    store.set(localStorage[name] ? decode(localStorage[name]) : initial)
  }
  return store
}

export const $storeLoaded = atom(false);

export const $cartStore = persistentAtom('cart', null);
export const $currency = persistentAtom('currency', 'USD');
export const $currencySymbol = persistentAtom('currencySymbol', '$');
export const $currencyRate = persistentAtom('currencyRate', 1);
export const $checkoutNavigation = persistentAtom('checkout_navigation', 'information');
export const $fbqAddToCart = persistentAtom('fbqAddToCart', null);
export const $fbqCheckout = persistentAtom('fbqCheckout', null);
export const $prices = persistentAtom('prices', null);
export const $shippingInfo = persistentAtom('shippingInfo', {});
export const $billingInfo = persistentAtom('billingInfo', {});
export const $gdprShown = persistentAtom('gdpr', undefined);
export const $gdprMarketing = persistentAtom('gdpr_marketing', true);
export const $gdprPreferences = persistentAtom('gdpr_preferences', true);
export const $gdprStatistics = persistentAtom('gdpr_statistics', true);

export const $productPrice = atom<null | number>(null);
export const $salePrice = atom<null | string>(null);
export const $regularPrice = atom<null | string>(null);
export const $productImage = atom<null | IGatsbyImageData>(null);
export const $checkoutErrorMessage = atom<null | string>(null);
export const $isCheckoutEnabled = atom(true);
export const $updateCartId = atom(0);
export const $stateRequired = atom(false);
export const $stateBillingRequired = atom(false);
export const $updatedConsent = atom(false);
export const $productAddToCart = atom(false);

export const updateProductImage: (imageData: IGatsbyImageData) => void = (imageData) => {
  $productImage.set(imageData);
};
export const updateSalePrice: (price: string) => void = (price) => {
  $salePrice.set(price);
};
export const updateRegularPrice: (price: string) => void = (price) => {
  $regularPrice.set(price);
};

export const formatPrice: (price: number) => string = (price: number) =>
  String((price / 100).toFixed(2));

export const formatPriceToCents: (price: string) => number = (price: string) =>
  Math.round(Number(price.replace(",", ".")) * 100);

const getPriceFromResponse = (res: any) => {
  const cartId = Object.keys(res?.cart)[0];
  return res.cart[cartId].line_total * 100;
};

export const loadSalePrice = action($salePrice, 'loadSalePrice', async (store, res) => {
  if (!res) return;
  store.set(res.product.sale_price);
})

export const loadRegularPrice = action($regularPrice, 'loadRegularPrice', async (store, res) => {
  if (!res) return;
  store.set(res.product.regular_price);
})

export const loadPrice = async () => {
  const res = await getPrice();
  await loadSalePrice(res);
  await loadRegularPrice(res);
}

export const fastUpdateProductQuantity: (key, quantity) => void = (key, quantity) => {
  const data = $cartStore.get();

  const stripe_cart = typeof data?.stripe_cart === 'object' && Object.keys(data.stripe_cart).length ? data.stripe_cart : new Object();
  const cart = typeof data?.cart === 'object' && Object.keys(data.cart).length ? data.cart : new Object();

  if (key in cart) {
    cart[key].quantity = quantity
  }

  $cartStore.set({ ...data, cart });
}

export const updateCartStore: (cart: CartData, updateCartId: Number) => void = (cart: CartData, updateCartId: Number) => {
  if (updateCartId !== $updateCartId.get()) {
    return;
  }

  $cartStore.set({ ...cart });
  localStorage.setItem('cart', JSON.stringify(cart));

  // if (cart?.limdisc?.start && cart?.limdisc?.time) {
  //   $limdisc.set(cart.limdisc.start + cart.limdisc.time);
  // }
};

export const loadCart = action($cartStore, "loadCart", async () => {
  let updateCartId = $updateCartId.get() + 1;
  $updateCartId.set(updateCartId);

  if (localStorage.getItem("session_cookie")) {
    return getCart().then(res => {
      if (res) {
        updateCartStore(res, updateCartId);
        $productAddToCart.set(false);
        return res;
      }
    });
  }
  else if (!$productAddToCart.get()) {
    updateCartStore('', updateCartId);
  }
  return null;
});

// prettier-ignore
export const applyCoupon = action($productPrice, 'applyCoupon', async (store, value) => {
  if (value === "") return;
  let updateCartId = $updateCartId.get() + 1;
  $updateCartId.set(updateCartId);

  const res = await validateCoupon(value);

  // handle errors
  if (res?.notice?.length > 0) {
    return res.notice[0].notice;
  }

  updateCartStore(res, updateCartId);
  return "Coupon applied successfully!";
})

export const removeCouponFromCart = action($cartStore, 'removeCoupon', async (store, value) => {
  let updateCartId = $updateCartId.get() + 1;
  $updateCartId.set(updateCartId);

  if (value === "") return;

  const data = $cartStore.get();
  const stripe_cart = typeof data?.stripe_cart === 'object' && Object.keys(data.stripe_cart).length ? data.stripe_cart : new Object();
  const cart = typeof data?.cart === 'object' && Object.keys(data.cart).length ? data.cart : new Object();
  if (data) {

    data.applied_coupons = [];
    data.applied_coupons_2 = {};

    if (stripe_cart?.order_data?.displayItems?.discount) {
      delete stripe_cart.order_data.displayItems.discount;
    }
    if (stripe_cart?.order_data?.displayItems?.subtotal?.amount && stripe_cart?.order_data?.total) {
      stripe_cart.order_data.total.amount = stripe_cart.order_data.displayItems.subtotal.amount
    }

    for (const item in cart) {
      if (cart[item].line_subtotal) {
        cart[item].line_total = cart[item].line_subtotal
      }
    }

  }
  $cartStore.set({ ...data });

  const res = await removeCoupon(value);

  // handle errors
  if (res?.applied_coupons?.length > 0) {
    return "Unknown error";
  }

  updateCartStore(res, updateCartId);
  return "Coupon removed successfully!";
})